<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
  <z-plate title="用户管理">
    <template #titleRight>
      <el-form inline>
        <el-form-item>
          <el-input v-model="form.schoolName" placeholder="学校名称" />
        </el-form-item>

        <el-form-item>
          <el-input v-model="form.name" placeholder="教师名称" />
        </el-form-item>

        <el-form-item>
          <el-select v-model="form.status" placeholder="帐户是否启用">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button icon="el-icon-search" @click="init">搜索</el-button>
          <el-button type="primary" @click="add">添加</el-button>
        </el-form-item>
      </el-form>
    </template>

    <!-- 表格 -->
    <el-table
      class="z-table"
      :data="tableData.content"
      v-loading="tableLoading"
      style="width: 100%"
    >

      <el-table-column type="index" label="序号" width="80"></el-table-column>

      <el-table-column prop="name" label="姓名"></el-table-column>

      <el-table-column prop="eduId" label="教师ID"></el-table-column>

      <el-table-column prop="schoolName" label="学校名称" show-overflow-tooltip></el-table-column>

      <el-table-column prop="sex" label="性别" width="60"></el-table-column>

      <el-table-column prop="subject" label="学科"></el-table-column>

      <el-table-column prop="birthday" label="出生日期" :formatter="dataFormat"></el-table-column>

      <el-table-column prop="loginName" label="登录用户名" width="180"></el-table-column>

      <el-table-column label="帐户是否启用">
        <template slot-scope="{ row }">
          <el-switch v-model="row.status == 0" @change="changeStatus(row.id)"></el-switch>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="200" align="center" >
      	<template slot-scope="{ row }">
          <el-button icon="el-icon-edit" @click="editUser(row)"></el-button>
        	<!-- <el-button @click="resetPassword(row.id)">重置密码</el-button> -->
          <el-button @click="editPassword(row.id)">修改密码</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <z-pagination
      v-if="pagination.total > 0"
      :page.sync="pagination.page"
      :limit.sync="pagination.limit"
      :total="pagination.total"
      @pagination="init"
    ></z-pagination>

<!-- 添加用户 -->
    <el-dialog title="用户信息" @close="resetUserForm('passwordForm')" :visible.sync="dialogFormVisible" :destroy-on-close="true" :close-on-click-modal="false" width="40%">
      <el-form :model="userForm" :rules="rules" ref="userForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="用户姓名" prop="name">
          <el-input v-model="userForm.name"></el-input>
        </el-form-item>
        <el-form-item label="登录名" >
          <el-input v-model="userForm.loginName" placeholder="默认为手机号" maxlength="20" ></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="telphone">
          <el-input v-model="userForm.telphone"></el-input>
        </el-form-item>
        <el-form-item label="学校" prop="schoolName">
          <el-input v-model="userForm.schoolName"></el-input>
        </el-form-item>
        <el-form-item label="出生日期" >
          <el-date-picker
              type="date"
              placeholder="选择日期"
              v-model="userForm.birthdayStr"
              style="width: 100%;"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="学科 " prop="subject">
          <el-input v-model="userForm.subject"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" >
          <el-input v-model="userForm.mail"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitUserForm('userForm')">确定</el-button>
          <el-button @click="resetUserForm('userForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 修改密码 -->
    <el-dialog title="修改密码" @close="resetUserForm('passwordForm')" :visible.sync="dialogPassword" :destroy-on-close="true" :close-on-click-modal="false" width="40%">
      <el-form :model="passwordForm" :rules="rules" ref="passwordForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="新密码" prop="password1">
          <el-input type="password" v-model="passwordForm.password1" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="password2" >
          <el-input type="password" v-model="passwordForm.password2" autocomplete="off"></el-input>
        </el-form-item>
        <label style="color: #F56C6C; font-size: 12px; margin-left: 50px;">提示：包含字母、数字、符号至少2种且不少于8位数</label>
        <el-form-item>
          <el-button type="primary" @click="submitPasswordForm('passwordForm')">确定</el-button>
          <el-button @click="resetUserForm('passwordForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </z-plate>
</template>

<script>
import { dateFormat } from '../../module/js/utils'
export default {
  data() {
    var validatePass1 = (_rules, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'));
      } else {
        var password = this.passwordForm.password1;
        if (password !== '') {
          var msg = this.testPassword(password);
          var length = password.length;
          // console.log('msg',msg,length);
          if(length<8 || length>20){
            callback(new Error('密码长度不符合要求'));
          }else if(msg != '1'){
            callback(new Error('密码强度不符合要求'));
          }else{
            callback();
            this.$refs.passwordForm.validateField('password1');
          }
        }else{
          callback();
        }
      }
    };
    var validatePass2 = (_rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.passwordForm.password1) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      form: {
        schoolName: "", //学校名
        name: "", //教师名
        status: "" //启用状态
      },
      options: [
        {
          value: "-",
          label: "全部"
        },
        {
          value: 0,
          label: "是"
        },
        {
          value: 1,
          label: "否"
        }
      ],

      tableData: [],
      pagination: {
        page: 0, //当前页
        limit: 10, //分页条数
        total: 1 //总页数
      },
      tableLoading: false,
      dialogFormVisible: false,
      dialogPassword: false,
      passwordForm: {
        password1:'',
        password2:''
      },
      formLabelWidth: '120px',
      userForm: {
        id: '',
        name: '',
        loginName: '',
        telphone: '',
        schoolName: '',
        birthdayStr: '',
        subject: '',
        mail: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入用户姓名', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ],
        telphone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '长度为11个字符', trigger: 'blur' }
        ],
        schoolName: [
          { required: true, message: '请输入学校', trigger: 'blur' }
        ],
        subject: [
          { required: true, message: '请输入学科', trigger: 'blur' }
        ],
        password1: [
          { validator: validatePass1, trigger: 'blur' }
        ],
        password2: [
          { validator: validatePass2, trigger: 'blur' }
        ],
      }
    };
  },
	
	mounted() {
		this.init();
	},
	
  methods: {
    dataFormat(row, column) {
      const daterc = row[column.property];
      if (!daterc) {
        return "";
      }
      return dateFormat(new Date(daterc), 'YYYY-MM-DD');
    },
    
    init() {
      this.$post('/admin/manageUserInfo', {
        schoolName : this.form.schoolName, 
        name: this.form.name, 
        status: this.form.status, 
        page: this.pagination.page,
        size: this.pagination.limit
      }).then((m) => {
        if(m.resultCode == 0) {
          this.tableData = m.content.pageList;
          this.pagination.total = m.content.pageList.total;
        }
      });
    },
    
    resetPassword(id) {
      this.$confirm("请确认是否重置？重置后密码为登录账号后6位", "重置密码", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$post("/admin/resetPwd", {
          id: id,
        }).then(m => {
          if(m.resultCode == 0) {
            this.$message({
              showClose: true,
              type: "success",
              message: "重置成功,密码为："+m.content.newpwd
            });
          }
        })	
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消!",
          showClose: true
        });
      })
    },
    testPassword(password){
      // let pwd = /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)^\S{8,20}$/;
      let pwd = /^(?!^\d+$)(?!^[a-z]+$)(?!^[A-Z]+$)(?!^[^a-z0-9]+$)(?!^[^A-Z0-9]+$)(?!^.*[\u4E00-\u9FA5].*$)^\S{8,20}$/;
      if(!pwd.test(password)) {
        return "0";
      }else{
        return "1";
      }
    },
    editPassword(id){
      this.dialogPassword = true;
      this.userForm.id = id;
    },
    submitPasswordForm(){
      // console.log("修改用户密码！", this.userForm.id);
      this.$post("/admin/editPassword",
        {"id":this.userForm.id,"password":this.passwordForm.password2}
      ).then(m => {
        if(m.resultCode == 0) {
          this.$message({
            showClose: true,
            type: "success",
            message: "操作成功!"
          });
          this.init();
          this.dialogPassword = false;
          this.resetUserForm('passwordForm');
        }else{
          this.$message({
            showClose: true,
            type: "warning",
            message: m.resultDesc
          });
        }
      })
    },

    changeStatus(val) {
      this.$post('/admin/changeStatus', {id: val}).then((m) => {
        if(m.resultCode == 0) {
          this.$message({
            showClose: true,
            type: "success",
            message: "操作成功!"
          });
          this.init();
        }
      })
    },
    add(){
      this.dialogFormVisible = true;
    },
    submitUserForm(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {

          let reg_phone = /^1[3-9]{1}[0-9]{9}$/; //手机
          let reg_mail = /^([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
          if(!reg_phone.test(this.userForm.telphone)) {
            return this.$message({
              showClose: true,
              type: 'error',
              message: '手机号码格式不正确！'
            });
          }
          if(!!this.userForm.mail && !reg_mail.test(this.userForm.mail)) {
            return this.$message({
              showClose: true,
              type: 'error',
              message: '邮箱格式不正确！'
            });
          }
          console.log('submit!',this.userForm);
          this.$post("/admin/saveTeacherInfo",
              this.userForm
          ).then(m => {
            if(m.resultCode == 0) {
              this.$message({
                showClose: true,
                type: "success",
                message: "保存成功!"
              });
              this.init();
              this.dialogFormVisible = false;
              this.resetUserForm('userForm');
            }else{
              this.$message({
                showClose: true,
                type: "warning",
                message: m.resultDesc
              });
            }
          })
        } else {
          console.log('error submit!!');
        }
      });
    },

    editUser(item){
      this.dialogFormVisible = true;
      this.userForm.id = item.id;
      this.userForm.name = item.name;
      this.userForm.loginName = item.loginName;
      this.userForm.telphone = item.telphone;
      this.userForm.schoolName = item.schoolName;
      this.userForm.subject = item.subject;
      this.userForm.birthdayStr = dateFormat(item.birthday, 'YYYY-MM-DD');
      this.userForm.mail = item.mail;
      console.log('item:',item,this.userForm);
    },

    resetUserForm(formName){
      this.$refs[formName].resetFields();
      this.userForm.loginName = '';
      this.userForm.birthdayStr = '';
      this.userForm.mail = '';
    },

  }
};
</script>

<style lang="scss" scoped>
.demo-ruleForm{
  width: 400px;
}
</style>